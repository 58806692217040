<template>
  <div class="box">
    <Header> 绑定支付宝 </Header>

    <div class="vessel">
      <div class="top_noe">
        <div class="left">姓名</div>
        <!-- <div>{{ name }}</div> -->
        <div class="right">
          <input
            v-model="name"
            class="top_text"
            type="text"
            placeholder="请输入姓名"
          />
        </div>
      </div>
      <div class="tel_box">
        <div class="left">支付宝账号</div>
        <div class="right">
          <input v-model="tel" type="text" placeholder="请输入支付宝账号" />
        </div>
      </div>
      <div class="graph_box">
        <div>
          <input v-model="code" type="text" placeholder="请输入图形验证码" />
        </div>
        <div class="verify-code_right none1" v-if="CodeImg == ''">
          <span
            class="white_color"
            style="font-size: 15px"
            @click="getGraphicalCode"
            >获取图形验证码</span
          >
        </div>
        <img :src="CodeImg" @click="getGraphicalCode" alt="" v-else />
        <!-- <div>获取图形验证码</div> -->
      </div>
      <div class="verification_box">
        <div>
          <input
            @input="specifiName($event)"
            v-model="auth"
            type="text"
            placeholder="请输入验证码"
          />
        </div>
        <!-- <div>获取验证码</div> -->
        <div class="verify-code_right">
          <span
            class="white_color"
            style="font-size: 15px"
            @click="codetxt1 == '获取验证码' ? getVerification() : ''"
            >{{ codetxt1 }}</span
          >
          <!-- <span class="white_color">获取验证码</span> -->
        </div>
      </div>
    </div>

    <div class="confirm" @click="ensure">确定</div>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  data() {
    return {
      tel: '',
      code: '',
      CodeImg: '',
      show: '',
      timer1: '',
      codetxt1: '获取验证码',
      auth: '',
      anyUserinfo: '',
      name: '',
      emailExp: new RegExp(
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      ),
      phoneExp: new RegExp(/^1[0-9]{10}$/),
    };
  },
  mounted() {
    // 个人信息
    // let params = {
    //   token: localStorage.getItem("token"),
    // };
    // this.$api.anyUserinfo(params).then((res) => {
    //   if (res.code == 0) {
    //     this.anyUserinfo = res.data;
    //     this.name = res.data.realName;
    //   }
    // });
  },
  methods: {
    getVerification() {
      let cb = this.$api.anyCheckcodeforgetForEmail;
      if (/^1[0-9]{10}$/.test(this.tel)) {
        cb = this.$api.anyCheckcodeforget;
      }
      // 加载中,遮罩层
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (!this.tel) {
        this.$toast('请输入支付宝账号');
        return;
      }
      if (!this.code) {
        this.$toast('请输入图形验证码');
        return;
      }
      let params = {
        tel: this.tel,
        code: this.code,
        email: this.tel,
      };

      // 获取手机号验证码
      cb(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示;
          toast.clear();
          this.$toast.success('验证码已发送');
          let n = 60;
          this.timer1 = setInterval(() => {
            this.codetxt1 = `${n--}s`;
            if (n == 0) {
              this.codetxt1 = '获取验证码';
              clearInterval(this.timer1);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail('获取失败');
        }
      });
    },
    // 获取图片验证码
    getGraphicalCode() {
      let cb = this.$api.getEmailGraphicalCode;
      if (!this.emailExp.test(this.tel) && !this.phoneExp.test(this.tel)) {
        return this.$toast('请输入正确格式账号');
      }
      if (/^1[0-9]{10}$/.test(this.tel)) {
        cb = this.$api.getGraphicalCode;
      }

      if (!this.tel) {
        this.$toast('请输入支付宝账号');
        return;
      }

      this.show = true;
      let params = {
        tel: this.tel,
        email: this.tel,
      };

      cb(params).then(
        (res) => {
          this.$toast.success('获取成功');
          this.CodeImg = (URL || webkitURL).createObjectURL(
            new Blob([res], { type: 'img/jpeg' }),
          );
        },
        (error) => {
          this.$toast(error.message);
        },
      );
    },
    specifiName(e) {
      // var that = this;
      var val = e.target.value;
      if (val != '') {
        let conf = document.querySelector('.confirm');
        conf.style.color = '#FFFFFF';
        conf.style.backgroundColor = '#0754D3';
      } else {
        this.$toast('验证码不能为空');
        let conf = document.querySelector('.confirm');
        conf.style.color = '#666666';
        conf.style.backgroundColor = '#eeeeee';
      }
    },

    // 确认
    ensure() {
      if (this.tel == '') {
        this.$toast('支付宝账号不能为空');
      }
      if (this.auth == '') {
        this.$toast('图像验证码不能为空');
      }
      if (this.auth == '') {
        this.$toast('验证码不能为空');
      }
      let params = {
        token: localStorage.getItem('token'),
        alipayAccount: this.tel,
        checkCode: this.auth,
        name: this.name,
      };
      this.$api.anyBindalipayaccount(params).then((res) => {
        if (res.code == 0) {
          this.$toast('绑定支付宝成功');
          this.$router.back();
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top_text {
  border: 0;
}
.confirm {
  font-size: 15px;
  color: #666666;
  text-align: center;
  height: 44px;
  line-height: 44px;
  width: 341px;
  margin-left: 17px;
  background-color: #eeeeee;
  margin-top: 43px;
}
.vessel {
  width: 341px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 20px;
}
.verification_box {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-left: 17px;
  margin-right: 10px;
  height: 62px;
  line-height: 62px;

  input {
    border: 0;
  }
  div {
    color: #0754d3;
  }
}
.graph_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-left: 17px;
  height: 62px;
  line-height: 62px;
  color: #0754d3;
  margin-right: 10px;
  input {
    border: 0;
    width: 130px;
  }
  img {
    width: 150px;
    height: 40px;
  }
}
.tel_box {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-left: 17px;
  height: 62px;
  line-height: 62px;
  .left {
    width: 50px;
  }
  .right {
    flex: 1;
  }
  input {
    border: 0;
  }
}
.top_noe {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  height: 62px;
  line-height: 62px;
  margin-left: 17px;
  margin-right: 10px;
  .left {
    width: 50px;
  }
  .right {
    flex: 1;
  }
}
.box {
  width: 100%;
  height: 100vh;
  background-color: #f3f4f8;
}
.none1 {
  span::before {
    content: ' ';
    display: flex;
    width: 0px;
    height: 0px;
    margin-right: 10px;
    background: rgba(218, 218, 218, 1);
  }
}
.verify-code_right {
  display: flex;
  width: 48%;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  span::before {
    content: ' ';
    display: flex;
    width: 1px;
    height: 19px;
    margin-right: 10px;
    background: #eeeeee;
  }
}
</style>
